<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <div class="form-group mb-0 break-spaces">
      <TextArea
        id="queixaPrincipal"
        :value="form.fields.queixaPrincipal"
        @blur="onBlur(form)"
        @inputTextArea="el => updateForm('queixaPrincipal', el)"
        :readonly="!this.validCanEdit()"
        rows="1"
        :placeholder="this.form.fields.queixaPrincipal ? this.form.fields.queixaPrincipal : 'Descrever'"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    TextArea: () => import('@/components/General/TextArea'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      canDefaultForms: state => state.attendance.canDefaultForms,
      form: state => state.attendance.form.queixaPrincipal,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore,
      selectedForm: state => state.attendance.form.selected,
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance', ['editDefaultForms']),
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord', 'defaultUpdateForm']),
    ...mapActions('attendance/form/queixaPrincipal', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    onBlur(value) {
      this.updateMedicalRecord(value)
      if(this.canDefaultForms) {
        this.defaultUpdateForm(value)
        this.$toast.success('Campo queixa principal salvo com sucesso')
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const queixaPrincipal = data.find(el => { return el.type === 'queixa-principal'})

      if (!queixaPrincipal?.id) return
      Object.keys(queixaPrincipal.value).map(key => {
        if (queixaPrincipal.value[key]) 
          this.updateForm(`${key}`, queixaPrincipal.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
    validCanEdit() {
      if(this.canDefaultForms === true || this.canEdit === true){
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedForm() {
      const form = this.selectedForm.forms.find(f => f.id === 'queixa-principal');
      if (form && form.default_text && this.canEdit) {
        setTimeout(() => {
          this.updateForm('queixaPrincipal', form.default_text)
					}, "3000")
      }
    },

  }
}
</script>
